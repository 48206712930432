<template>
  <div id="news-wrapper" :class="{open: open, 'neon-glow': open}">
    <div class="tab" @click="open = !open">
      News Feed
    </div>

    <div class="overflow-hidden">
      <div class="news">
        <div class="news-item" v-for="(post, index) in posts" :key="post.id">
          <hr v-if="index > 0" />
          <h3>{{ post.Title }}</h3>
          <p>{{post.PublishedOn | dateFromNow}} | <a :href="post.source.Homepage" target="_blank">{{post.source.Title}}</a>
          <p v-html="post.Content"></p>
          <p>
            <a :href="post.Link" target="_blank">Read More &raquo;</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Layout',
  data () {
    return {
      open: false,
      posts: [],
    }
  },
  mounted() {
    fetch('https://cms.playpinquest.com/contents?_sort=PublishedOn:desc')
      .then(response => response.json())
      .then(data => this.posts = data);
  },
  filters: {
    dateFromNow: function (date) {
      return moment(date).fromNow();
    }
  }
}
</script>

<style lang="scss">
$pink: #fc0fc0;
$neon: #8000ff;
#news-wrapper {
  position: absolute;
  box-sizing: border-box;
  width: calc(100vw - 3rem);
  top: 1rem;
  bottom: 6rem;
  right: 0;
  border: 3px solid $pink;
  border-right: 0 none;
  background: black;
  transform: translateX(100%);
  border-radius: 6px 0 0 0;
  .overflow-hidden {
    overflow: hidden;
    height: 100%;
  }
  .tab {
    background: black;
    position: absolute;
    bottom: 0;
    right: 100%;
    padding: 0.5rem;
    border: 3px solid $pink;
    border-bottom: 0 none;
    width: 6rem;
    text-align: center;
    transform: rotate(-90deg) translateY(calc(7rem + 6px)) translateX(-3px);
    transform-origin: bottom left;
    border-radius: 6px 6px 0 0;
    filter: drop-shadow(0 0 10px $neon);
  }
  &.open {
    transform: translateX(0);
    filter: drop-shadow(0 0 10px $neon);
    .tab {
      filter: none;
    }
  }
  .news {
    padding: 1rem;
    overflow-y: scroll;
    height: 100%;
  }
  h3:first-child {
    margin-top: 0;
  }
}
</style>
