import Vue from 'vue/dist/vue.esm'

import Layout from '../news/layout.vue'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#news',
    components: { Layout }
  })
})
